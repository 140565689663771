import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCardDetails, resubscribePlan, updateTransaction, stripeSession, createCardUpdateCheckoutSession } from '../../Subscription/service';
import { getUserLastSubscriptionDetails } from "../../../network/service";
import { getUserDetails } from "../../../Redux/UserDetailsSlice/UserDetailsSlice";
import './scss/resubscribe.css'
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from "react-toastify";

const Resubscribe = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const accessToken = useSelector((state) => state?.accessToken?.value);
    const dispatch = useDispatch();
    const userData = useSelector((state) => state?.userDetails?.value);

    const appInfo = {
        projectDetails: projectInfo,
        accessToken: accessToken,
    };

    const userSubscriptionId = location?.state?.userSubscriptionId;

    const [cardDetails, setCardDetails] = useState();
    const [activeSubscriptions, setActiveSubscriptions] = useState();
    const [isProcessing, setIsProcessing] = useState(false)
    const [isProcessed, setIsProcessed] = useState(false)

    useEffect(() => {
        isProcessed && setTimeout(() => {
            window.location.href = '/home'; // to call authenticate api
        }, 1000);
    }, [isProcessed])

    useEffect(() => {

        if (!userSubscriptionId) {
            navigate('/')
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

            fetchUserLastSubscriptionDetails();
            fetchCardDetails();
        }
    }, []);

    const fetchUserLastSubscriptionDetails = async () => {

        const subscriptionResponse = await getUserLastSubscriptionDetails(appInfo);

        if (subscriptionResponse?.status === 200) {
            setActiveSubscriptions(subscriptionResponse?.data?.data);
        }

    }

    const fetchCardDetails = async () => {

        try {
            const subscriptionResponse = await getCardDetails(appInfo, userSubscriptionId);

            if (subscriptionResponse?.status == 200) {
                setCardDetails(subscriptionResponse?.data?.data)
            } else {
                toast.error(subscriptionResponse?.data?.message)
            }
        } catch (error) {
            toast.error(error)
        }


    }

    const changeCard = async () => {
        const sessionResponse = await createCardUpdateCheckoutSession(appInfo, userSubscriptionId);

        if (sessionResponse?.status == 200) {
            window.location.href = sessionResponse?.data?.url;

        } else {
            toast.error('Something went wrong, Please try again later!');

        }
    }

    const resubscribe = async () => {
        setIsProcessing(true);
        const resubscribeResponse = await resubscribePlan(appInfo, userSubscriptionId);

        if (resubscribeResponse?.status == 200) {
            setIsProcessing(false);
            setIsProcessed(true);
            toast.success('Resubscribed successfully!');

            dispatch(
                getUserDetails({
                    userDetails: {
                        ...userData,
                        ['user_subscription_id']: null
                    },
                })
            );

        } if (resubscribeResponse?.status == 202) {

            const resubscribeWithOtpResponse = resubscribeResponse?.data?.data;

            if (resubscribeWithOtpResponse?.requiresAction) {

                const stripe = await loadStripe(resubscribeWithOtpResponse?.publishableKey);

                const { error, paymentIntent } = await stripe.confirmCardPayment(resubscribeWithOtpResponse?.clientSecret);

                if (error) {
                    console.error('Payment failed:', error);
                    // Handle payment failure
                } else if (paymentIntent.status === 'succeeded') {
                    updateTransaction(appInfo, resubscribeWithOtpResponse)
                    setIsProcessing(false);
                    setIsProcessed(true);

                    // Handle payment success
                } else {
                    console.log('Payment requires further action:', paymentIntent.status);
                    // Handle other statuses
                }
            } else {
                console.error('Subscription failed');
                // Handle subscription failure
            }

        } else {
            console.log('resubscription failed', resubscribeResponse);

        }

    }

    const changePlan = () => {
        navigate('/checkout')
    }

    return (
        <div className="resubscribe-page">

            <div className="membership-wrapper">
                <div className="membership-title"><h3>Membership</h3></div>
                <div className="plan-details-wrapper">
                    <div className="plan-details-inner-wrapper">
                        <div className="plan-details">
                            <div>
                                <div className="plan-title">
                                    {activeSubscriptions?.subscription_name}
                                </div>
                                {activeSubscriptions?.expired_price && <div className="plan-description">
                                    ${activeSubscriptions?.expired_price} {activeSubscriptions?.subscription_name}
                                </div>}

                            </div>
                            <div>
                                <div className="plan-change" onClick={() => { changePlan() }}>
                                    {'Change plan?'}
                                </div>
                                {!isProcessed && <div className="plan-change resubscribe">
                                    <div className="mt-10">Reactivate Your Membership for Just ${activeSubscriptions?.current_price} {activeSubscriptions?.subscription_name}?</div>
                                    <div className="restart-membership" onClick={() => { !isProcessing && resubscribe() }}>{isProcessing ? 'Processing...' : 'Resubscribe'}</div>
                                </div>}
                                {isProcessed && <div className="plan-change resubscribe">
                                    <div className="mt-10 c-green ta-c resubscribe-success">Your membership has been reactivated successfully!</div>
                                </div>}
                            </div>


                        </div>
                        <div className="plan-details w-30">
                            <div className="plan-description card-details">
                                {cardDetails?.type == 'card' && <Cards
                                    expiry={cardDetails?.exp_month + cardDetails?.exp_year}
                                    focused={'number'}
                                    name={cardDetails?.cardholder_name}
                                    number={'**** **** **** ' + cardDetails?.last4}
                                    preview={true}
                                    issuer={cardDetails?.brand}
                                />}

                                {cardDetails?.type == 'link' && <div className="card-details">
                                    <div>
                                        <p><strong>{cardDetails?.cardholder_name}</strong></p>
                                        <p><strong>{cardDetails?.email}</strong></p>
                                        <p><strong>{cardDetails?.country}</strong> <strong>{cardDetails?.postal_code}</strong></p>
                                    </div>
                                    <div>
                                        <svg className="InlineSVG LinkButton-logoSvg" focusable="false" viewBox="0 0 72 24" fill="none" width="50px">
                                            <path fill="#011E0F" d="M36.12 3.677c0-1.128.95-2.045 2.069-2.045 1.118 0 2.069.922 2.069 2.045a2.075 2.075 0 0 1-2.07 2.069 2.057 2.057 0 0 1-2.068-2.07ZM29.98 1.92h3.6v20.16h-3.6V1.92ZM40.008 7.68h-3.629v14.4h3.629V7.68ZM66.096 14.39c2.731-1.68 4.589-4.18 5.323-6.715H67.79c-.945 2.42-3.115 4.239-5.5 5.011V1.916h-3.63v20.16h3.63V16.08c2.77.691 4.958 3.086 5.707 5.995h3.653c-.557-3.053-2.645-5.909-5.554-7.685ZM46.44 9.293c.95-1.263 2.803-1.997 4.306-1.997 2.803 0 5.121 2.05 5.126 5.146v9.633h-3.629v-8.832c0-1.272-.566-2.74-2.405-2.74-2.16 0-3.403 1.915-3.403 4.156v7.426h-3.629V7.69h3.634v1.603ZM12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"></path>
                                            <path fill="#fff" d="M11.448 4.8h-3.7c.72 3.01 2.821 5.582 5.452 7.2-2.635 1.618-4.733 4.19-5.453 7.2h3.7c.918-2.784 3.457-5.203 6.577-5.698v-3.01c-3.125-.489-5.664-2.908-6.576-5.692Z"></path>
                                        </svg>
                                    </div>
                                </div>}
                            </div>

                            <div className="plan-change ta-c" onClick={() => { changeCard(activeSubscriptions?.subscription_id) }}>
                                {'Change payment method?'}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Resubscribe;