import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";
import { convertAdUrl, getEventInfo, imageUrlCheck, onVideoPlayFunction } from "../../utils/utils";
import { getLiveInfo, getVideoDetails } from "./service";
import { ReactComponent as BackButton } from "../../assets/Icons/backButton.svg";
import { ReactComponent as LeftArrow } from "../../assets/Icons/leftArrow.svg";
import { getPlayerToken, updateVideoWatchlistData, getUserSubscription, updateWatchlistData, authenticate } from "../../network/service";
import { getVideoSubscription } from "../Subscription/service";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import { fetchWatchlistShows } from "../MyList/service";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getMoreInfoOther } from "../../Redux/MoreInfo/moreInfoOtherSlice";
import { getMoreInfo } from "../../Redux/MoreInfo/moreInfoSlice";
import Comment from '../Videoplayer_and_Comments/Components/Comment';
import Loading from "../../Components/Loading/Loading";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import ShowsRow from "../../Components/ShowsRow/ShowsRow";
import { getShowDetails } from "../ShowDetailsScreen/service";
import ShowCard from "../../Components/ShowCard/ShowCard";
import PlayNextEpisode from "../../Components/Modals/PlayNextEpisode";
import WatchWithoutAdsModal from '../../Components/Modals/WatchWithoutAdsModal';
import ContinueWatchingModel from "../../Components/Modals/ContinueWatchingModel";
import Autoplay from "./Components/Autoplay";
import Translate from "../../Components/MultiLanguage/Translate";
import LogoutAllModal from "../../Components/Modals/LogoutAllModal";
import CardStyle from "../../Components/cards/CardStyle";
import LandscapeThumbnailCard from "../../Components/cards/LandscapeThumbnailCard";
let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
let videoPlaying = false;
const Videoplayer = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const user = useSelector((state) => state?.user?.value);
  const isMoreInfoOther = useSelector((state) => state?.moreInfoOther?.value);


  const [vPlayer, setVPlayer] = useState();
  const [videoUrl, setVideoUrl] = useState("");
  const [videoEventUrl, setVideoEventUrl] = useState("");
  const [watchedDuration, setWatchedDuration] = useState();
  const [adUrl, setAdUrl] = useState();
  const [loading, setLoading] = useState(true);
  const [liveDetails, setLiveDetails] = useState();
  const [subtitleInfo, setSubTitleInfo] = useState();
  const [subscribedUser, setSubscribedUser] = useState(true);
  // const [videoDetails, setVideoDetails] = useState();
  const [subscriptions, setSubscriptions] = useState();
  const [watchListStatus, setWatchListStatus] = useState();
  const [showVideo, setShowVideo] = useState(false);
  const [isAutoplayView, setIsAutoplayView] = useState(false);
  const [watchWithoutAds, setWatchWithoutAds] = useState(false);
  const [isContinueWatchingModal, setIsContinueWatchingModal] = useState(false)
  const [selectedAudioLanguage, setSelectedAudioLanguage] = useState({})
  const [multiLanguageOptions, setMultiLanguageOptions] = useState([])
  const [upNext, setUpNext] = useState([])
  const [watchedTime, setWatchedTime] = useState(0)
  const [videoTitle, setVideoTitle] = useState("")
  const [logoutModal, setLogoutModal] = useState(false);
  const [modalType, setModalType] = useState({ type: "logoutAll" });
  const [selectedVideoDetails, setSelectedVideoDetails] = useState();
  const [userSubscriptionData, setUserSubscriptionData] = useState([]);




  const { } = useParams();
  const { vanityUrl } = useParams();
  const location = useLocation();
  const isLive = location?.state?.isLive;
  const watchedLength = location?.state?.watchedLength;
  const videodetail = location?.state?.videoDetails
  const subcheck = location?.state?.notSubCheck
  const [videoDetails, setVideoDetails] = useState({});
  const eventId = location?.state?.eventId;
  const showDetails = location?.state?.showDetails
  const previousUrl = location?.state?.prevLocation ? location?.state?.prevLocation : "/home";
  const videoType = location?.state?.showDetails?.type === "linear_event" ? "event" : "video";
  // const databaseRef = firebase.database().ref('comment');
  const videoId = location?.state?.videoDetails?.video_id;
  const showId = showDetails?.show_id;
  // const showUniqueId = showId;
  let showUniqueId = isMoreInfoOther?.showId ? isMoreInfoOther?.showId : showId;
  const playerRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      '&:hover': {
        borderColor: 'grey',
      },

    }),

    menu: (provided) => ({
      ...provided,
      top: '100%',
      bottom: 'auto',
      backgroundColor: 'grey',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '200px',
    }),

    singleValue: (provided) => ({
      ...provided,
      color: 'grey',
    }),

    placeholder: (provided) => ({
      ...provided,
      color: 'white',
    }),
  };
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId,
  };


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    // let vanityUrl = location?.pathname.split("/")[2];
    setVPlayer(null)
    setWatchedDuration(0)
    setWatchedTime(0)
    setSelectedAudioLanguage({})
    setLoading(true)

    fetchVideoDetails(vanityUrl)
  }, [location?.pathname]);



  useEffect(() => {
    window.scroll(0, 0)
    setVPlayer(null)
    if (isLive) {
      setLoading(true)
      fetchLiveInfo()
    } else if (eventId) {
      setLoading(true)
      fetchEventInfo()
    }
    else {
      handleVideoPlayback();
    }
  }, [videoDetails, subscriptions])




  useEffect(() => {
    setVPlayer(null);
    if (videoUrl && !watchWithoutAds && !isContinueWatchingModal) {
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${videoDetails?.type == 'event' ? videoEventUrl : videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],
        // poster:require("../../../images/thumb.png")
      };

      setVPlayer(
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
          adUrl={adUrl ? adUrl : null}
          subtitles={subtitleInfo}
        />
      );
    }
  }, [videoUrl, watchWithoutAds, isContinueWatchingModal]);

  const fetchVideoSubscription = async (videoId) => {
    const type = "video";
    try {
      return await getVideoSubscription(appInfo, videoId, type);
    } catch (err) { }
  };
  const fetchLiveInfo = async () => {
    try {
      const response = await getLiveInfo(appInfo);
      setLiveDetails(response?.data?.data)
      setVideoUrl(response?.data?.data[0]?.live_link)
    } catch (err) {
      setLoading(false)
    }
  }

  const fetchEventInfo = async () => {
    try {
      const response = await getEventInfo(appInfo, eventId);
      setLiveDetails(response?.data?.data)
      setVideoUrl(response?.data?.data?.live_url)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  const subscriptionCheck = (userSub, videoSub) => {
    return userSub?.some((user) => videoSub?.some((video) => user?.sub_id === video?.subscription_id));
  };
  const handleVideoPlayback = async () => {
    if (logoutLimitCheck()) {
      return;
    }
    if (videoDetails, subscriptions) {
      localStorage.setItem("showId", showDetails?.show_id);
      localStorage.setItem("vanityUrl", videoDetails?.vanity_url);
      localStorage.setItem("redirectURL", location.pathname);
      if (subscriptions?.length === 0) {
        if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
          if (!user) {
            // console.log('videoDetails', videoDetails);
            if (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true') {
              localStorage.setItem("showId", showDetails?.show_id);
              localStorage.setItem("vanityUrl", videoDetails?.vanity_url);
              navigate("/checkout", { state: { videoId: videoDetails?.video_id, isFree: videoDetails?.free_video } });
            } else {
              navigate("/login", { state: { ...location?.state, path: location?.pathname, showId: showId, isFrom: true } });
            }
          }
          else if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
            setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo, isLive));
            playVideo();
          } else {
            playVideo();
          }
        }
        else if (projectInfo?.projectConfig?.config?.AD_REQUIRED === "true") {
          if (userSubscriptionData?.login_needed === false) {
            handleClose();
            setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo, isLive));
            playVideo();
          } else if (userSubscriptionData?.login_needed === true) {
            setModalType({ type: "videoWatchLimitExpired" });
            setLogoutModal(true);
          }
          else {
            setAdUrl(convertAdUrl(videoDetails, showDetails, appInfo, isLive));
            playVideo();
          }
        }
        else {
          if (userSubscriptionData?.login_needed === false) {
            handleClose();
            playVideo();
          } else if (userSubscriptionData?.login_needed === true) {
            setModalType({ type: "videoWatchLimitExpired" });
            setLogoutModal(true);
          }
          else {
            playVideo();
          }
        }
      }
      else if (subscriptions?.length > 0 && videoDetails?.free_video) {
        logoutLimitCheck();
        if (subscriptionCheck(userSubscriptionData?.data, subscriptions)) {
          playVideo();
        } else {
          setWatchWithoutAds(true);
        }
      }
      else {
        logoutLimitCheck();
        if (subscriptionCheck(userSubscriptionData?.data, subscriptions)) {
          playVideo();
        } else {
          if (!user) {
            if (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true') {
              localStorage.setItem("showId", showDetails?.show_id);
              localStorage.setItem("vanityUrl", videoDetails?.vanity_url);
              navigate("/checkout", { state: { videoId: videoDetails?.video_id, isFree: videoDetails?.free_video } });
            } else {
              navigate("/login", { state: { ...location?.state, path: location?.pathname, showId: showId, isFrom: true } });
            }
          } else {
            if (projectInfo?.projectConfig?.config?.WEBSITE_NEW_CHECKOUT_REQUIRED == 'true') {
              localStorage.setItem("showId", showDetails?.show_id);
              localStorage.setItem("vanityUrl", videoDetails?.vanity_url);
              navigate("/checkout", { state: { videoId: videoDetails?.video_id } });
            } else {
              navigate("/subscription", { state: { videoId: videoDetails?.video_id } });
            }
          }
        }

      }
    }


  };

  const logoutLimitCheck = () => {
    if (userSubscriptionData?.forcibleLogout === true) {
      setLogoutModal(true);
      setModalType({
        type: "logoutAll",
        heading: "You’ve reached the maximum Device limit.Do you want to logout from all devices",
      });
      return true;
    } else if (userSubscriptionData?.session_expired === true) {
      setModalType({ type: "redirect" });
      setLogoutModal(true);
      setTimeout(() => {
        handleClose();
        redirectToLogin();
      }, 1000);
      return true;
    }
    return false;
  }

  const playVideo = async (watchedLength) => {
    setShowVideo(true);
    if (watchedLength) {
      setWatchedDuration(watchedLength);
    }
    if (videoDetails?.watched_duration > 0) {
      setIsContinueWatchingModal(true);
    }
    setSubTitleInfo(videoDetails?.subtitles);
    const arr = videoDetails?.video_name?.split("/").reverse();
    const playerTokenData = await fetchPlayerToken(arr[1]);

    if (playerTokenData) {
      setVPlayer(null);
      setVideoEventUrl(videoDetails?.video_name)
      setVideoUrl(
        "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
        arr[1] +
        "&token=" +
        playerTokenData +
        "&type=" +
        videoType +
        "&pubid=" +
        projectInfo?.projectConfig?.pubid
      );
    }
  };

  const fetchPlayerToken = async (videoId) => {
    const playerTokenResponse = await getPlayerToken(appInfo, videoId);
    if (playerTokenResponse?.status === 200) {
      return playerTokenResponse?.data?.data;
    }
  };
  const handleClose = () => {
    document.body.className = "hideScroll";
    if (isMoreInfoOther?.isModal) {
      if (isMoreInfoOther?.isFromSearch) {
        if (location?.state?.isFromHome) {
          navigate("/home");
        }
        setTimeout(() => {
          dispatch(
            getMoreInfoOther({
              isModal: false,
              showId: null,
              isFromSearch: false,
            })
          );
        }, 600);
      } else {
        setTimeout(() => {
          dispatch(
            getMoreInfoOther({
              isModal: false,
              showId: null,
            })
          );
        }, 600);
      }
    } else {
      setTimeout(() => {
        dispatch(
          getMoreInfo({
            moreInfo: false,
          })
        );
      }, 500);
    }

  };
  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("play", () => {
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      videoPlaying = true;
      updateVideoAnalytics(event, player.currentTime());
    });
    player.on("loadedmetadata", () => {
      // player.pause();
      if (watchedTime > 0) {
        player.currentTime(watchedTime.toString());
        // player.play();
        // videoPlaying = true;
      } else if (watchedDuration > 0) {
        player.currentTime(watchedDuration.toString());
        // player.play();
        // videoPlaying = true;
      }
    });
    player.on("timeupdate", function (e) {
      let event = "POP03";
      videoPlaying = true;
      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        updateVideoAnalytics(event, player.currentTime());
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {
      let event = "POP04";
      videoPlaying = true;
      updateVideoAnalytics(event, player.currentTime());
    });
    player.on("ended", () => {
      let event = "POP05";
      videoPlaying = false;
      // episodeCount = findIndex() + 1;
      updateVideoAnalytics(event, player.currentTime());
      if (videoDetails?.up_next?.length > 0) {
        setIsAutoplayView(true);
      }

      if (upNext?.length > 0) {
        navigate(`/videos/${upNext[0]?.vanity_url}`, { state: { videoDetails: upNext[0] } })
      }
    });
    player.on("dispose", () => {
      videoPlaying = false;
      videoStarted = false;
      player.pause();
    });
  };

  const updateVideoAnalytics = async (event, time) => {
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      videoDetails,
      event,
      time,
      "0",
      location?.state?.categories,
      showDetails?.show_id
    );
  };

  const fetchVideoDetails = async (video_vanity_url) => {
    try {
      await fetchUserSubscriptionDetails();
      const response = await getVideoDetails(appInfo, video_vanity_url);
      if (response?.status === 200) {
        setMultiLanguageOptions(response.data.data.audio_languages);
        const videoSubResponse = await fetchVideoSubscription(response?.data?.data?.video_id);
        if (videoSubResponse?.status === 200) {
          setVideoDetails(response?.data?.data);
          setVideoTitle(response?.data?.data?.video_title)
          setUpNext(response?.data?.data?.up_next)
          setSubscriptions(videoSubResponse?.data?.data);
          setLoading(false)
          if (response?.data?.data?.watchlist_flag === 1) setWatchListStatus("added");
        } else {
          setWatchListStatus("removed");
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const createVideoUrl = async (video) => {
    const response = await getVideoDetails(appInfo, video?.value);
    response?.data?.data?.up_next && setUpNext(response?.data?.data?.up_next)
    const arr = video.video_name?.split("/").reverse();
    const playerTokenData = await fetchPlayerToken(arr[1]);

    if (playerTokenData) {
      setVPlayer(null);
      setVideoUrl(
        "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
        arr[1] +
        "&token=" +
        playerTokenData +
        "&type=" +
        videoType +
        "&pubid=" +
        projectInfo?.projectConfig?.pubid
      );
    }
  }

  const multiLanguageDropdownHandler = async (selectedOption) => {
    setSelectedAudioLanguage({ value: selectedOption.value, label: selectedOption.label, video_name: selectedOption.video_name })
    setWatchedTime(playerRef.current.currentTime())
    setVideoTitle(selectedOption.video_title)
    createVideoUrl(selectedOption)
  }

  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      setUserSubscriptionData(userSubResponse?.data);
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };



  // const backButtonHandler = () => {
  //   navigate(previousUrl, { state: { showId: showDetails?.show_id } });
  // };

  const searchHandler = (value, type) => {
    navigate(`/search?query=${value}`, { state: { type } });
  };
  const redirectToLogin = () => {
    dispatch(
      getUser({
        user: null,
      })
    );
    localStorage.removeItem("userId");
    tokenAuthenticate();
    navigate("/login", {
      state: { ...location?.state, path: location?.pathname, showId: showUniqueId },
    });
  };
  const tokenAuthenticate = async () => {
    const response = await authenticate(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
    }
  };

  const goBack = () => {
    window.history.back();
  };



  if (loading) {
    return <Loading />;
  }

  return (
    <div className="videoPlayerContainer">
      <div className="wrapper">

        {watchWithoutAds && (
          <WatchWithoutAdsModal
            setWatchWithoutAds={setWatchWithoutAds}
            showDetails={showDetails}
            appInfo={appInfo}
            setAdUrl={setAdUrl}
            videoDetails={videoDetails}
            playVideo={playVideo}
            setIsContinueWatching={setIsContinueWatchingModal}
          />
        )}
        {isContinueWatchingModal && (
          <ContinueWatchingModel
            setIsContinueWatchingModal={setIsContinueWatchingModal}
            watchedLength={watchedLength}
            videoDetails={videoDetails}
            playVideo={playVideo}
            showDetails={showDetails}
            appInfo={appInfo}
            setAdUrl={setAdUrl}
          />
        )}
        {logoutModal && (
          <LogoutAllModal
            showId={showUniqueId}
            appInfo={appInfo}
            setLogoutModal={setLogoutModal}
            modalType={modalType}
            videoDetails={videoDetails}
            handleClose={handleClose}
          />
        )}
        <div className="arrowContainer" onClick={goBack}>
          <h3>
            <LeftArrow />
          </h3>
        </div>

        <div className="playerContainer">
          {vPlayer && vPlayer}
          {/* {
            isAutoplayView &&
            <Autoplay episodes={videoDetails?.up_next} setIsAutoplayView={setIsAutoplayView} />
          } */}
        </div>
        <div className="multiAudio" >
          {multiLanguageOptions?.length > 1 &&
            <div className="selectContainer">
              <Select
                styles={customStyles}
                options={multiLanguageOptions.map((video) => ({
                  value: video.video_id,
                  label: video.language_name,
                  video_name: video.video_name,
                  video_title: video.video_title
                }))}
                placeholder={"Choose Language..."}
                onChange={multiLanguageDropdownHandler}
                value={selectedAudioLanguage && Object.keys(selectedAudioLanguage)?.length !== 0 ? selectedAudioLanguage : null}
              />
            </div>}
        </div>
        <div className="videoMetaData">
          <div className="head">
            <h1 className="videoTitle">{videoTitle}</h1>
          </div>
          <div className="categoriesAndRating">
            <div className="categories">
              {videoDetails?.category_name?.map((item, index) => (
                <span className="category">{item}</span>
              ))}
            </div>

            {/* <span className="rating">PG</span> */}
          </div>
          <p className="description">
            {videoDetails?.video_description && parse(videoDetails?.video_description?.split("\n")?.join("<br>"))}
          </p>

          <div className="extraInfoContainer">
            {videoDetails?.video_tags?.length > 0 && (
              <div className="metaItem">
                <h6 className="subHeading">Tags</h6>
                <div className="items oneRow">
                  {videoDetails?.video_tags?.length > 0 &&
                    videoDetails?.video_tags?.map((item, index) => (
                      <span className="item" onClick={() => searchHandler(item, "tags")}>
                        {item}
                        <span className="comma">,</span>
                      </span>
                    ))}
                </div>
              </div>
            )}
            {videoDetails?.cast?.length > 0 && (
              <div className="metaItem">
                <h6 className="subHeading">Cast</h6>
                <div className="items">
                  {videoDetails?.cast?.map((item, index) => (
                    <div className="item" key={item?.id}>
                      <span className="itemInfo" onClick={() => searchHandler(item?.name, "cast")}>
                        {item?.name}
                      </span>
                      <span className="itemLabel">{item?.role}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {videoDetails?.crew?.length > 0 && (
              <div className="metaItem">
                <h6 className="subHeading">Crew</h6>
                <div className="items">
                  {videoDetails?.crew?.map((item, index) => (
                    <div className="item" key={item?.id}>
                      <span className="itemInfo" onClick={() => searchHandler(item?.name, "crew")}>
                        {item?.name}
                      </span>
                      <span className="itemLabel">{item?.role}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

      </div>

      <div className="wrapper">
        {upNext?.length > 0 && (
          <div className="upNextContainer">
            <h1 className="heading"><Translate textKey={'upnext'} /> {videoDetails?.season && `in Season ${videoDetails?.season}`} </h1>
            <div className="upNext" onClick={() => setIsAutoplayView(false)}>
              {upNext?.map((video) => {
                return <div className={projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION == "PORTRAIT" ? "itemPortrait" : "item"}>
                  {projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION == "PORTRAIT" ?
                    <CardStyle
                      image={video?.thumbnail}
                      title={video?.video_title}
                      movieTime={video?.duration_text}
                      watchlistLink={`/videos/${video?.vanity_url}`}
                      link={`/videos/${video?.vanity_url}`}
                      data={video}
                    />
                    :
                    <LandscapeThumbnailCard
                      image={video?.thumbnail_350_200}
                      title={video?.video_title}
                      movieTime={video?.duration_text}
                      watchlistLink={`/videos/${video?.vanity_url}`}
                      link={`/videos/${video?.vanity_url}`}
                      data={video}
                    />}
                </div>
              })}
            </div>
          </div>
        )}
      </div>
      {videoDetails?.files?.length > 0 && (
        <div className="wrapper">
          <div className="files">
            <ShowsRow data={videoDetails?.files} type="pdf" />
          </div>
        </div>
      )}
      <span className="line"></span>
      {projectInfo?.projectConfig?.config?.COMMENTS_REQUIRED === "true" &&
        <div className="wrapper">
          <Comment videoId={videoDetails?.video_id} projectInfo={projectInfo} />
        </div>
      }
    </div>
  );
};

export default Videoplayer;
